import EntryPoint from 'Engine/EntryPoint';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EveryMatrix from './Manager';
import GameModel from '../../model/GameModel';
import BaseGameSettings from 'Engine/base/settings/BaseGameSettings';

export default new class Adapter {
  constructor() {

    GlobalDispatcher.add('preloaderController:loadingPreloaderProgress', this.loadProgress, this);
    GlobalDispatcher.add('game:gameStarted', this.loadCompleted, this);
    GlobalDispatcher.add('model:betChanged', this.stakeUpdate, this);
    GlobalDispatcher.add('gameSettings:soundMute', this.audioToggle, this);
    GlobalDispatcher.add('gameSettings:musicMute', this.audioToggle, this);
    GlobalDispatcher.add('stateStart:requestRollingState', this.roundStart, this);
    GlobalDispatcher.add('stateStart:requestRespinRollingState', this.roundStart, this);
    GlobalDispatcher.add('stateStart:idleState', this.roundEnd, this);
    GlobalDispatcher.add('showQuitWindow', this.closed, this);
    GlobalDispatcher.add('infoPopupManager:showError', this.errorMessage, this);
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {
    GlobalDispatcher.dispatch('dataProvider:updateBalance');
  }

  stopAutoSpins() {
    EntryPoint.AutoPlaySettings.active = false;
  }

  setAudio(value) {
    BaseGameSettings.soundMute = !value;
    BaseGameSettings.musicMute = !value;
    BaseGameSettings.winningMute = !value;

    //this is hack
    const keys = Object.keys(SoundManager._blurMutedState);
    for (const key of keys) {
      SoundManager._blurMutedState[key] = !value;
    }
  }

  showHelp() {
    GlobalDispatcher.dispatch('ui:gameInfoClick');
  }

  togglePaytable() {
    GlobalDispatcher.dispatch('ui:paytableClick');
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    EveryMatrix.loadProgress(data.params);
  }

  loadCompleted() {
    EveryMatrix.loadCompleted();
    const audioState = !BaseGameSettings.soundMute || !BaseGameSettings.musicMute || !BaseGameSettings.winningMute;
    EveryMatrix.audioToggle(audioState);
    EveryMatrix.stakeUpdate(GameModel.totalBet);
  }

  audioToggle() {
    const audioState = !BaseGameSettings.soundMute || !BaseGameSettings.musicMute || !BaseGameSettings.winningMute;
    EveryMatrix.audioToggle(audioState);
  }

  roundStart() {
    if (EntryPoint.GameModel.isFreeSpinsMode) return;
    EveryMatrix.roundStart();
  }

  roundEnd() {
    if (EntryPoint.GameModel.isFreeSpinsMode) return;
    EveryMatrix.roundEnd();
  }

  stakeUpdate() {
    EveryMatrix.stakeUpdate(GameModel.totalBet);
  }

  closed() {
    EveryMatrix.closed();
  }

  errorMessage(data) {
    if (data.params.message === 'info_message1_text') return;
    const message = {
      errorCode: '8501',
      errorMessage: data.params.message
    };
    EveryMatrix.errorMessage(message);
  }
}
