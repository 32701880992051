import GameModel from '../model/GameModel';
import XXXDataParser from './XXXDataParser';

export default new class ServerManager {
    constructor() {
        this.timeout = null;
        this._initManagerParams = {
            developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=fruitybook&no_redirect&no_op_launcher',
            cheatsParams: ['force_reels', 'force_jackpot', 'skip_freespins'],
            baseData: {
                ver: '1.1'
            }
        };
    }

    async onInit() {
        window.OPWrapperService.serverManager.initManagerGameRequests(this._initManagerParams);
        await window.OPWrapperService.serverManager.sendGameInitRequest();
        const data = await this._sendRequest({}, 'init');
        GameModel.setNewData(XXXDataParser.parseInit(data));
    }

    async onStartSpin() {
        let request = {
            betIndex: GameModel.betIndex,
        };
        const data = await this._sendRequest(request, 'bet');
        GameModel.setNewData(XXXDataParser.parseSpin(data));
        return data;
    }

    async onStartRespin() {
        let request = {
            type: 'respin',
            respin_reel_number: GameModel.respinReelIndex,
        };
        const data = await this._sendRequest(request, 'bet');
        GameModel.setNewData(XXXDataParser.parseRespin(data));
        return data;
    }

    async onBuyBonus() {
        let request = {
            betIndex: GameModel.betIndex,
        };
        const data = await this._sendRequest(request, 'buy_bonus');
        OPWrapperService.ControllerStatistic.increaseBuyBonusCount(GameModel.betIndex);
        GameModel.setNewData(XXXDataParser.parsePurchaseBonusGame(data));
        return data;
    }

    async _sendRequest(data, id, urlPath = 'game_request') {
        data.id = id;
        return await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
    }
}
